import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Nav,
  Row,
  Card,
  Progress,
  Spinner,
  CardFooter,
} from "reactstrap"
import { Link } from "react-router-dom"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import {
  FastForex_FetchOnlyOne,
  ConvertNumricToComaSeparate,
  objCurrrency,
  Get_All_FeaturedNGOs,
  Case_Of_The_Day,
} from "utils/CommonMethods"
import { baseImageUrl } from "utils/Api"
import CenteredLoader from "components/GeneralComponent/CenteredLoader"
import { DefaultImgPath, SetupMasterIds } from "utils/Constants"
import FeaturedNGOsCard from "components/FeaturedNGOsCard"
import { Img } from "react-image"

const SpecialAppeals = (props) => {
  const [loading, setLoading] = useState(false)
  // const [caseddl, setCaseddl] = useState([])

  // const CaseOfTheDay = async () => {
  //   try {
  //     const data = await Case_Of_The_Day(0, 0)
  //     const donationIDs = data?.Table2[0]?.DonationIDs.split(",").map(Number)
  //     const case1 = data?.Table && data?.Table.length > 0 ? data?.Table : null
  //     const case2 = data?.Table1.filter((item) => {
  //       return donationIDs.includes(item.donationsubcategoryid)
  //     })

  //     if (data != null) {
  //       const dataToAdd = []
  //       if (case1?.length > 0) {
  //         case1.map((item) => {
  //           dataToAdd.push(item)
  //         })
  //       }
  //       if (case2?.length > 0) {
  //         case2.map((item) => {
  //           dataToAdd.push(item)
  //         })
  //       }

  //       setCaseddl(dataToAdd)

  //       return data
  //     } else {
  //       return []
  //     }
  //   } catch (error) {
  //     console.log("SpecialAppeals", error)
  //     return []
  //   }
  // }
  // useEffect(() => {
  //   CaseOfTheDay()
  // }, [])

  const [selectedcurrencyValues, setselectedcurrencyValues] =
    useState(objCurrrency)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    const load = async () => {
      let _SessionData = JSON.parse(
        sessionStorage.getItem("globalSelectedCurrency")
      )
      let _CurrencyData = await FastForex_FetchOnlyOne(
        _SessionData.Flex1,
        "PKR"
      )

      if (_CurrencyData.Response) {
        let _parseData = JSON.parse(_CurrencyData.Data)
        selectedcurrencyValues.ConversionRate = _parseData.result.PKR
        selectedcurrencyValues.CurrencyFromSymbol = _parseData.base

        setselectedcurrencyValues({ ...selectedcurrencyValues })
      } else {
      }
    }
    load()
  }, [])
  const list= [10282,5074,5617,11702,9368,361,336,337];
  return (
    <section className="section section-meals section-cases pt-4 pb-4">
      <Container>
        <Row>
          <Col>
            <h2 className="meals-title mb-4">
              <span className="title-bg"> Services & Fundraisers </span>
            </h2>
          </Col>
        </Row>
        <div className="IndicatorCarousel">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={7500}
            shouldResetAutoplay={false}
            keyBoardControl={true}
            autoPlayInterval={7500}
            interval={7500}
            transitionDuration={500}
            containerClass="carousel-container"
            //  containerClass="carousel-container justify-content-center"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            removeArrowOnDeviceType={["tablet"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            pauseOnHover={true}
          >
            {props?.caseddl && props?.caseddl?.length > 0 ? (
              // props?.caseddl.map((items, i) => {
                list.map(id => props?.caseddl?.find(obj => (obj?.ApplicantCaseId || obj?.donationsubcategoryid) == id)).map((items, i) => {
                return items ? (
                  <Card body key={i} style={{background:'#EDF2FA'}}>
                    <div className="donation-box text-center">
                      <div className="" style={{ minHeight: "280px" }}>
                        <div className="dimg-box">
                          <Link
                          to={{
                            pathname:
                              items?.taglineid && items?.taglineid === 34
                                ? items?.ConstantValue
                                  ? `/case-detail/${items.ConstantValue}`
                                  : `/RamazanCampaignDonate/${items?.donationsubcategoryid}`
                                : items?.taglineid && items?.taglineid === 35
                                ? `/meals-donate/${items.donationsubcategoryid}`
                                : `/case-detail/${items.ApplicantCaseId}`,
                            state: items,
                          }}
                          rel="noreferrer"
                          >
                          <Img
                            src={[
                              items?.DocAttachment
                                ? baseImageUrl + items?.DocAttachment
                                : baseImageUrl + items?.AppealImages,
                              DefaultImgPath,
                            ]}
                            loader={<CenteredLoader />}
                            alt=""
                          />
                          </Link>
                        </div>

                        <h3 className="text-center mt-2 mb-2 card-tile-heading one-line-heading ">
                          {items?.CaseTitle
                            ? items?.CaseTitle
                            : items?.SetupDetailName}{" "}
                        </h3>
                        <p className="text-center qurbari-booking mb-0 two-lines-paragraph">
                          {items?.ShortDesc ? items?.ShortDesc : items?.tagline}

                          <br />
                          {/* {data?.ShortDesc.substring(0, 66) + ".."} */}
                        </p>
                      </div>
                      <CardFooter>
                        <Link
                          to={{
                            pathname:
                              items?.taglineid && items?.taglineid === 34
                                ? items?.ConstantValue
                                  ? `/case-detail/${items.ConstantValue}`
                                  : `/RamazanCampaignDonate/${items?.donationsubcategoryid}`
                                : items?.taglineid && items?.taglineid === 35
                                ? `/meals-donate/${items.donationsubcategoryid}`
                                : `/case-detail/${items.ApplicantCaseId}`,
                            state: items,
                          }}
                          className="btn btn-primary mt-3 w-50"
                          rel="noreferrer"
                          style={{background:'#062150'}}
                        >
                          Donate Now
                        </Link>
                      </CardFooter>
                    </div>
                  </Card>
                ) : null
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <CenteredLoader />
              </div>
            )}
          </Carousel>
        </div>
      </Container>
    </section>
  )
}

export default SpecialAppeals
